import IRoute from "../types/route";
import NotFound from "../common/notFound";
import SidebarLayout from "../common/layouts.ts/SidebarLayout";
import ChangePasswordLazy from "./ChangePasswordLazy";
import UserProfileLazy from "./UserProfileLazy";
import AdminDetailsLazy from "./AdminDetailsLazy";
import AdminOverviewLazy from "./AdminOverviewLazy";
import GroupDetailsLazy from "./GroupDetailsLazy";
import GroupsLazy from "./GroupsLazy";
import TeamDetailsLazy from "./TeamDetailsLazy";
import TeamsLazy from "./TeamsLazy";
import TargetsSurveyLazy from "./TargetsSurveyLazy";
import TargetDetailsLazy from "./TargetDetailsLazy";
import TargetsOverviewLazy from "./TargetsOverviewLazy";
import AssessmentDetailsLazy from "./AssessmentDetailsLazy";
import AssessmentsOverviewLazy from "./AssessmentsOverviewLazy";
import DashboardLazy from "./DashboardLazy";
import Notifications from "../components/Notifications";

const routes: IRoute[] = [
  {
    layout: SidebarLayout,
    name: "Sidebar Layout",
    path: "/",
    routes: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardLazy,
      },
      {
        path: "/people",
        name: "People",
        component: AssessmentsOverviewLazy,
      },
      {
        path: "/people/:id",
        name: "People Details",
        component: AssessmentDetailsLazy,
      },
      {
        path: "/positions",
        name: "Positions",
        component: TargetsOverviewLazy,
      },
      {
        path: "/positions/:targetId",
        name: "Position Details",
        component: TargetDetailsLazy,
      },
      {
        path: "/positions/survey/:targetId",
        name: "Targets Survey",
        component: TargetsSurveyLazy,
      },
      {
        path: "/folders",
        name: "Folders",
        component: GroupsLazy,
      },
      {
        path: "/folders/:groupId",
        name: "Folder Details",
        component: GroupDetailsLazy,
      },
      {
        path: "/teams",
        name: "Teams",
        component: TeamsLazy,
      },
      {
        path: "/teams/:groupId",
        name: "Team Details",
        component: TeamDetailsLazy,
      },
      {
        path: "/users/",
        name: "Users",
        component: AdminOverviewLazy,
      },
      {
        path: "/users/:userId?",
        name: "User Details",
        component: AdminDetailsLazy,
      },
      {
        path: "/profile",
        name: "Profile",
        component: UserProfileLazy,
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "/change-password",
        name: "Change Password",
        component: ChangePasswordLazy,
      },
      {
        path: "/password-forget",
        name: "Password Forget",
        component: ChangePasswordLazy,
      },
      {
        path: "*",
        name: "Not Found",
        component: NotFound,
      },
    ],
  },
];

export default routes;
