import { FC, Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Auth from "../services/auth";
import routes from "../config/routes";
import IRoute from "../types/route";
import http from "../services/http";
import SidebarLayout from "../common/layouts.ts/SidebarLayout";
import PasswordForgetForm from "./ForgetPasswordForm";
import ResetForgetPasswordForm from "./ResetForgetPasswordForm";
const Dashboard = lazy(() => import('./Dashboard/Dashboard'));
const LoginForm = lazy(() => import('./LoginForm'));
const UserAssessment = lazy(() => import('./ui/UserAssessment'));
const TargetsSurvey = lazy(() => import('./TargetsSurvey'));
const ChangePassword = lazy(() => import('../common/PasswordChange'));
const EntityOverview = lazy(() => import('./GodMode/GodModeOverview'));
const EntityDetails = lazy(() => import('./Entity/EntityDetails'));

interface Props {
    user: any;
    setUser: (u: any) => void;
}

const MainApp: FC<Props> = ({ user, setUser }) => {

    const IS_GOD_MODE_USER = user ? user.user.isGodModeUser : false; // hardcoded GodMode userId
    const IS_ADVISOR = user ? user.user.isAdvisor : false;
    const [isDefaultPassword, setIsDefaultPassword] = useState<boolean>(false);

    const [darkMode, setDarkMode] = useState<'light' | 'dark' | 'system'>(() => {
        const savedMode = localStorage.getItem('darkMode');
        if (savedMode === 'light' || savedMode === 'dark' || savedMode === 'system') {
            return savedMode;
        }
        return 'system';
    });

    const setDarkModeWithSave = (mode: 'light' | 'dark' | 'system') => {
        setDarkMode(mode);
        localStorage.setItem('darkMode', mode);
    };

    useEffect(() => {
        const applyDarkMode = (isDark: boolean) => {
            if (isDark) {
                document.body.classList.add('dark-mode');
            } else {
                document.body.classList.remove('dark-mode');
            }
        };

        if (darkMode === 'system') {
            const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            applyDarkMode(mediaQuery.matches);

            const handleChange = (e: MediaQueryListEvent) => applyDarkMode(e.matches);
            mediaQuery.addEventListener('change', handleChange);
            return () => mediaQuery.removeEventListener('change', handleChange);
        } else {
            applyDarkMode(darkMode === 'dark');
        }
    }, [darkMode]);

    const handleLogin = async (email: string, password: string) => {
        try {
            const data = await Auth.loginWithEmail(email, password);
            setUser(data);
            localStorage.setItem("user", JSON.stringify(data));
            http.setAuthToken(data.token);
            window.location.href = "/"; // Navigate to the desired page after login
        } catch (err) {
            console.error("Login error:", err);
            throw err;
        }
    };

    useEffect(() => {
        const localUser = user?.user;
        if (localUser) {
            setIsDefaultPassword(!localUser.isDefaultPasswordChanged);
        } else {
            setIsDefaultPassword(false);
        }
    }, [user?.user]);

    const isPublicRoute = window.location.hostname === 'assessment.aptiveindex.com';

    return (
        <BrowserRouter>
            <Routes>
                {isDefaultPassword ?
                    <Route path="*" element={<SidebarLayout darkMode={darkMode} setDarkMode={setDarkModeWithSave}><Suspense><ChangePassword /></Suspense></SidebarLayout>} />
                    :
                    <>
                        <Route
                            path="/login"
                            element={!user ? (
                                <Suspense><LoginForm onLogin={handleLogin} /></Suspense>
                            ) : (
                                <Navigate replace to="/" />
                            )} />
                        <Route
                            path="/password-forget"
                            element={!user ? (
                                <PasswordForgetForm />
                            ) : (
                                <Navigate replace to="/" />
                            )} />
                        <Route
                            path="/reset-forget-password/:token?"
                            element={!user ? (
                                <ResetForgetPasswordForm />
                            ) : (
                                <Navigate replace to="/" />
                            )} />
                        <Route path="/ui/assessment/:token?" element={<Suspense><UserAssessment /></Suspense>} />
                        <Route
                            path="/"
                            element={isPublicRoute ? (
                                <Suspense><UserAssessment /></Suspense>
                            ) : !user ? (
                                <Navigate replace to="/login" />
                            ) : (
                                <Suspense><SidebarLayout darkMode={darkMode} setDarkMode={setDarkModeWithSave}><Dashboard /></SidebarLayout></Suspense>
                            )} />

                        {/* Add a fallback route for handling direct token in the path */}
                        <Route path="/:token" element={<Suspense><UserAssessment /></Suspense>} />

                        <Route path="/positions/survey/:targetId" element={<Suspense><TargetsSurvey /></Suspense>} />

                        {
                            (IS_GOD_MODE_USER || IS_ADVISOR) &&
                            <>
                                <Route path="/entities/" element={<SidebarLayout darkMode={darkMode} setDarkMode={setDarkModeWithSave}><Suspense><EntityOverview /></Suspense></SidebarLayout>} />
                                <Route path="/entities/:entityId?" element={<SidebarLayout darkMode={darkMode} setDarkMode={setDarkModeWithSave}><Suspense><EntityDetails /></Suspense></SidebarLayout>} />
                            </>
                        }

                        {user ? (
                            routes.map((route: IRoute, index) => {
                                const { layout: Layout } = route;
                                return (
                                    <Route
                                        element={
                                            <Layout
                                                darkMode={darkMode}
                                                setDarkMode={setDarkModeWithSave}
                                            />
                                        }
                                        path={route.path}
                                        key={`${route.layout}${index}`}
                                    >

                                        <Route path="/" element={<Dashboard />} />
                                        {route.routes.map((newRoute) => {
                                            const { name, path, component: Component } = newRoute;
                                            return (
                                                <Route
                                                    key={`${newRoute.path}${index}`}
                                                    path={path}
                                                    element={<Component name={name} {...route.props} />}
                                                />
                                            );
                                        })}
                                    </Route>
                                );
                            })
                        ) : (
                            <>
                                <Route path="/ui/assessment" element={<Suspense><UserAssessment /></Suspense>} />
                                <Route path="*" element={<Navigate replace to="/login" />} />
                            </>
                        )}
                    </>}
            </Routes>
        </BrowserRouter>
    );
};

export default MainApp;
