import http from "./http/index";

const Auth = {
  loginWithEmail: async (email: string, password: string) => {
    return http
      .post<any>("/api/auth/login", {
        email,
        password,
      })
      .then((res) => res.data);
  },
  passwordResetToken: async (email: string) => {
    return http
      .post<any>("/api/auth/mail-reset-token", {
        email
      })
      .then((res) => res);
  },
  passwordReset: async (newPassword: string, confirmPassword: string, token: string)=>{
    return http
    .post<any>("/api/auth/reset-forgot-password", {
      newPassword, 
      confirmPassword,
      token
    })
    .then((res)=>res)
  }
};

export default Auth;
